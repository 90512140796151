<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATUS')} (*)`"
          :placeholder="$t('COMMON.STATUS')"
        >
          <el-select
            name="status"
            v-model.lazy="equipment.status"
            placeholder="status"
            @change="
              (status) => {
                equipment.status = status;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(label, val) in statusesOption"
              :key="val"
              :value="val"
              :label="label"
            ></el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.status" />
      </div>
    </div>
    <base-input
      :label="`${$t('EQUIPMENTS.PURCHASE_YEAR')}`"
      :placeholder="$t('EQUIPMENTS.PURCHASE_YEAR')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          enableTime: true,
          dateFormat: 'Y-m-d',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="equipment.purchase_year"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.purchase_year" />

    <div class="row">
      <div class="col">
        <base-checkbox v-model="equipment.is_loan" class="my-5">
          <span class="form-control-label"> Prêté par le fournisseur </span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.is_loan" />
      </div>
    </div>
    <div v-if="equipment.is_loan">
      <base-input
        :label="`${$t('COMMON.SUPPLIER')}`"
        :placeholder="$t('COMMON.SUPPLIER')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
          !hideOrganization
        "
      >
        <supplier-selector
          :filterOrganization="equipment.organization?.id"
          :supplier="equipment.supplier?.id"
          :filterable="true"
          :showAll="false"
          @supplierChanged="
            (supplier) => {
              equipment.supplier.id = supplier;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.cafeteria" />
    </div>

    <base-input
      :label="`${$t('EQUIPMENTS.LOAN_YEAR')} (*)`"
      :placeholder="$t('EQUIPMENTS.LOAN_YEAR')"
      v-if="equipment.is_loan"
    >
      <flat-picker
        :config="{
          allowInput: true,
          enableTime: true,
          dateFormat: 'Y-m-d',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="equipment.loan_year"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.loan_year" />
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !hideOrganization
          "
        >
          <organization-selector
            :organization="equipment.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                equipment.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!equipment.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CATEGORY_EQUIPMENTS')} (*)`"
          :placeholder="$t('COMMON.CATEGORY_EQUIPMENTS')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
            !hideOrganization
          "
        >
          <category-equipment-selector
            :categoryEquipment="equipment.categoryEquipment?.id"
            :filterable="true"
            :showAll="false"
            @categoryEquipmentChanged="
              (categoryEquipmentId, categoryEquipment) => {
                equipment.categoryEquipment.id = categoryEquipmentId;
                categoryEquipmentModel = categoryEquipment;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.serviceCenter" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="equipment.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.QUANTITY')} (*)`"
          :required="true"
          v-model="equipment.quantity"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.quantity" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.BRAND')} (*)`"
          :required="true"
          v-model="equipment.brand"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.brand" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CODE')}`"
          v-model="equipment.code"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.code" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.SERIAL_NUMBER')}`"
          v-model="equipment.serial_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.serial_number" />
      </div>
    </div>
    <div
      class="row"
      v-if="
        showCashRegisterTerminalPointField ||
        showKitchenField ||
        showOfficeEquipmentField ||
        showSealerField ||
        showDisplayField ||
        showCareProductField
      "
    >
      <div class="col">
        <base-input
          :label="`${$t('EQUIPMENTS.MODEL')}`"
          v-model="equipment.model"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.model" />
      </div>
    </div>

    <div
      class="row"
      v-if="
        showVendingMachineField ||
        showCashRegisterTerminalPointField ||
        showOfficeEquipmentField ||
        showSealerField ||
        showDisplayField ||
        showCareProductField
      "
    >
      <div class="col">
        <base-input
          :label="`${$t('EQUIPMENTS.SERVICE_PLAN')}`"
          v-model="equipment.service_plan"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.service_plan" />
      </div>
    </div>
    <div class="row" v-if="showKitchenField">
      <div class="col">
        <base-input
          :label="`${$t('EQUIPMENTS.FORMAT')}`"
          v-model="equipment.format"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.format" />
      </div>
    </div>
    <div
      class="row"
      v-if="showCashRegisterTerminalPointField || showOfficeEquipmentField"
    >
      <div class="col">
        <base-input
          :label="`${$t('EQUIPMENTS.SOFTWARE')}`"
          v-model="equipment.software"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.software" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.GUARANTEE')}`"
          v-model="equipment.guarantee"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.guarantee" />
      </div>
    </div>

    <div class="">
      <base-input
        :label="`${$t('COMMON.NOTE')}`"
        :placeholder="$t('COMMON.NOTE')"
      >
        <html-editor v-model="equipment.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>
    <div class="row">
      <div class="col">
        <base-checkbox v-model="equipment.is_internal" class="my-5">
          <span class="form-control-label"> Propriétaire Laniel</span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.is_internal" />
      </div>
    </div>
    <div>
      <base-input
        :label="`${$t('COMMON.SERVICECENTER')}`"
        :placeholder="$t('COMMON.SERVICECENTER')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          !hideOrganization
        "
      >
        <service-center-selector
          :filterOrganization="equipment.organization?.id"
          :serviceCenter="equipment.serviceCenter?.id"
          :filterable="true"
          :showAll="false"
          @serviceCenterChanged="
            (serviceCenterId) => {
              equipment.serviceCenter.id = serviceCenterId;
              equipment.establishment.id = null;
              equipment.cafeteria.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.serviceCenter" />
    </div>
    <div>
      <base-input
        :label="`${$t('COMMON.ESTABLISHMENT')}`"
        :placeholder="$t('COMMON.ESTABLISHMENT')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          !hideOrganization
        "
      >
        <establishment-selector
          :filterOrganization="equipment.organization?.id"
          :establishment="equipment.establishment?.id"
          :filterable="true"
          :filterServiceCenter="equipment.serviceCenter?.id"
          :showAll="false"
          @establishmentChanged="
            (establishmentId) => {
              equipment.establishment.id = establishmentId;
              equipment.cafeteria.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.establishment" />
    </div>

    <div>
      <base-input
        :label="`${$t('COMMON.CAFETERIA')}`"
        :placeholder="$t('COMMON.CAFETERIA')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
          !hideOrganization
        "
      >
        <cafeteria-selector
          :filterOrganization="equipment.organization?.id"
          :cafeteria="equipment.cafeteria?.id"
          :filterEstablishment="equipment.establishment?.id"
          :filterable="true"
          :showAll="false"
          @cafeteriaChanged="
            (cafeteriaId) => {
              equipment.cafeteria.id = cafeteriaId;

              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.cafeteria" />
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          equipment.id
            ? $t("EQUIPMENTS.EDIT_EQUIPMENT")
            : $t("EQUIPMENTS.ADD_EQUIPMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { equipmentStatusesOption } from "@/constants/equipments";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import CategoryEquipmentSelector from "@/components/CategoryEquipmentSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    ServiceCenterSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    CategoryEquipmentSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: [
    "equipmentData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let equipmentData = { ...this.equipmentData };
    equipmentData = this.$fillUserOrganizationData(equipmentData);
    return {
      statusesOption: equipmentStatusesOption,
      equipment: equipmentData,
      categoryEquipmentModel: null,
    };
  },

  created() {},
  computed: {
    showVendingMachineField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name === "Distributrices automatiques"
      );
    },
    showCashRegisterTerminalPointField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name ===
          "Caisse enregistreuse et terminal point de vente (TPV)"
      );
    },
    showKitchenField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name ===
          "Équipements et accessoires de cuisines"
      );
    },
    showOfficeEquipmentField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name === "Équipement de bureau"
      );
    },
    showDecorarionSignalizationField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name ===
          "Accessoires de décoration et signalisation"
      );
    },
    showSoftwareField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name === "Équipements pour service traiteur"
      );
    },
    showCareProductField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name ===
          "Tour de dissolution de produit d’entretien ménager"
      );
    },
    showDisplayField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name === "Présentoir"
      );
    },
    showSealerField() {
      return (
        this.categoryEquipmentModel &&
        this.categoryEquipmentModel.name === "Scelleuse"
      );
    },
  },
  methods: {
    async handleSubmit() {
      let equipmentData = cloneDeep(this.equipment);
      equipmentData = this.$fillUserOrganizationData(equipmentData);
      this.$emit("equipmentSubmitted", equipmentData);
    },

    resetExternalFields() {
      if (!this.is_internal) {
        this.equipment.serviceCenter = { type: "service-centers", id: null };
        this.equipment.establishment = { type: "establishments", id: null };
        this.equipment.cafeteria = { type: "cafeterias", id: null };
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    equipmentData(equipmentData) {
      if (equipmentData) {
        this.equipment = {
          ...this.equipment,
          ...cloneDeep(equipmentData),
        };
        this.categoryEquipmentModel = { ...this.equipment.categoryEquipment };
      }
    },
  },
};
</script>
