import {
  EQUIPMENT_STATUS_AVAILABLE,
  
} from "@/constants/equipments";

export default {
  type: "equipments",
  name: null,
  code: null,
  brand: null,
  serial_number: null,
  guarantee: null,
  excerpt: null,
  is_internal: false,
  is_loan: false,
  model: null,
  purchase_year: null,
  service_plan: null,
  format: null,
  software: null,
  loan_year: null,
  quantity: null,

  status: EQUIPMENT_STATUS_AVAILABLE,
  relationshipNames: [
    "organization",
    "establishment",
    "serviceCenter",
    "cafeteria",
    "categoryEquipment",
    "supplier",
  ],
  supplier: {
    type: "suppliers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  categoryEquipment: {
    type: "category-equipments",
    id: null,
  },
  allowedUsers: [],

  
};
